<template>
  <div class="text-left LoginNew" style="overflow-x:hidden;">
    <div v-if="isLoading">
      <LoaderComp/>
    </div>
    <div v-else class="row h-100">
      <div class="col-12 col-sm-12 col-md-12 col-lg-7 MainSection">
        <div class="">
    
          <div class="px-5">
            <img
              src="/assets/img/new-website/LogoQuizell.svg"
              style="height: 40px; cursor: pointer"
              alt="Quizell"
              class="img-fluid"
              @click="openWebsite"
            />
          </div>

          <div class="TitleDiv">
            <h1 class="title">Log In to your account</h1>
          </div>

          

          <div class="mt-5 px-5">
            <div class="new-form-element mt-3 px-5">
              <p class="m-0 pl-1">
                Enter your email address <span style="color: red">*</span>
              </p>
              <div class="NewFormInput d-flex align-items-center w-100 py-2">
                <input
                  type="email"
                  class="mx-2 flex-grow-1"
                  placeholder="example@mail.com"
                  v-model="loginDetail.email"
                />
              </div>
            </div>
            <div class="new-form-element mt-3 px-5">
              <p class="m-0 pl-1">
                Enter your password <span style="color: red">*</span>
              </p>
              <div class="NewFormInput d-flex align-items-center w-100 py-2">
                <input
                  @keyup.enter="LogIn"
                  :type="passwordFieldType"
                  class="mx-2 flex-grow-1"
                  placeholder="Enter your password"
                  v-model="loginDetail.password"
                />
                <div
                  style="cursor: pointer"
                  class="mr-2"
                  v-if="passwordFieldType == 'text'"
                >
                  <b-icon-eye style="color: #d1d1d1" @click="switchPass" />
                </div>
                <div
                  style="cursor: pointer"
                  class="mr-2"
                  v-else
                  @click="switchPass"
                >
                  <b-icon-eye-slash style="color: #d1d1d1" />
                </div>
              </div>
            </div>
            <div class="mt-4 px-5">
              <button
                :disabled="!isLoginValid"
                class="btn w-100 NewFormInput-Btn px-3 py-2"
                @click="LogIn"
              >
                <span v-if="!loginSpinner">Login</span>
                <div
                  v-else
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>

         <div class="mt-5">
            <p class="text-center register">
             strong Don’t have an account yet?
             <button class="btn m-0 p-0 font-weight-bold" style=" color: #4d1b7e;"  @click="$router.push('/sign-up')">
                Register for free.
              </button>
             
            </p>
            <p class="text-center register">
              Forgot password?
              <button

                class="btn m-0 p-0" style="color: #4d1b7e;"
                @click="$router.push('/password/reset')"
              >
               <strong> Get a fresh start</strong>
              </button>
            </p>
          </div>
          
        </div>
      </div>
      <div class="d-none d-lg-block col-lg-5 h-100 objectSection">

        
        <LazyImage
                    src="/assets/img/LoginObject.png"
                    :title="`Quizell login`"
                    :alt="`Quizell login`"
                    class="h-100 w-100"
                  />
      </div>
    </div>
  </div>
</template> 

<script>
import axios from 'axios'
import LoaderComp from "../customize/component/LoaderComp.vue";
import { BIconEyeSlash, BIconEye } from "bootstrap-vue";
export default {
  name: "Login",
  components: {
    BIconEyeSlash,
    BIconEye,
    LoaderComp
  },

  data() {
    return {
      
      loginDetail: {
        password: "",
        email: "",
      },
      passwordFieldType: "password",
      loginSpinner: false,
      isLoading:false
    };
  },
  created(){
    
     if(Object.entries( this.$route.query).length !== 0 ){
     if(this.$route.query.token){
        this.isLoading = true
        localStorage.setItem('user_access_token', this.$route.query.token);
        this.$router.push("/manage/quizzes").then(() => { this.$router.go(0) })
     }
     }
    let user_access_token = localStorage.getItem("user_access_token") 
   if( user_access_token !== null){
          window.open("/","_self")
        
         }
     
  
},
  methods: {
    openWebsite(){
      window.open(this.getMainAppUrl,"_self")
    },
    switchPass() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    async LogIn() {
      if (this.isLoginValid) {
        this.loginSpinner = true;
        try {
          let data = {
            email: this.loginDetail.email,
            password: this.loginDetail.password,
          };
          const response = await axios.post(`/login`, data);
          if (response.status == 201 && response.data.status == "fail") {
            const Error = response.data.data;

            if (typeof Error == "object") {
              for (const key in Error) {
                this.$toasted.show(`${Error[key]}`, {
                  theme: "toasted-primary",
                  type: "error",
                  position: "bottom-center",
                  duration: 2000,
                });
              }
            } else if (typeof Error == "string") {
              this.$toasted.show(`${Error}`, {
                theme: "toasted-primary",
                type: "error",
                position: "bottom-center",
                duration: 2000,
              });
            } else {
              this.$toasted.show("Error occured", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 4000,
              });
            }
          } else if (response.status == 200) {
            if (response.data.access_token) {
              localStorage.setItem(
                "user_access_token",
                response.data.access_token
              );
              this.$router.push('/')
             
            }
          } else if (response.status == 206) {
            this.$toasted.show(`${response.data.error}`, {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 4000,
            });

            localStorage.setItem(
              "user_access_token",
              response.data.access_token
            );
            this.$router.push("/email-verify");
          }
        } catch (error) {
          console.log("Error", error);
          throw error;
        } finally {
          this.loginSpinner = false;
        }
      } else {
        this.$toasted.show("Invalid Form", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 4000,
        });
      }
    },
   
    
  },
  computed: {
    isLoginValid() {
      return this.loginDetail.email !== "" && this.loginDetail.password !== "";
    },
    getMainAppUrl()
        {
         
        return process.env.VUE_APP_MAIN_WEBSITE
        }
  },
};
</script>
<style scoped>
.LoginNew {
  height: 100vh;
}
.h2{
font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;

                  color: #161e34;
}
.LoginNew .MainSection {
  padding: 2% 5%;
  background: #ffffff;
}
.MainSection .TitleDiv {
  /* padding: 48px; */
  padding: 30px 48px 20px 48px;
}
.MainSection .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #161e34;
}
.objectSection {
  /* background-image: url("~/assets/new-website/loginObject.png"); */
  background-color: #ffffff;
  height: 100%;
  /* background-repeat: no-repeat;
  background-size: 100% 100vh; */
}
.loginContainer{
  padding:0 5%;
}

.NewFormInput-Btn {
  background: #4d1b7e;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}
.NewFormInput-Btn:hover {
  background: #ffc000;
  color: #ffffff;
}
.NewFormInput-Btn:disabled:hover {
  background: #4d1b7e !important;
}
.NewFormInput {
        border: 1px solid #4D1B7E;
    border-radius: 10px;
        box-sizing: border-box;
        background:#ffffff;
    }
    
    .NewFormInput input {
        background: transparent;
        border: none;
        outline: none;
    }
    .NewFormInput input:focus {
        border: none;
        outline: none;
    }
    .NewFormInput input::placeholder {
       font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    
    text-align: start;
    
    color: #D1D1D1;
    }
    
    .NewFormInput-Btn{
        background: #4D1B7E;
    border-radius: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    }
    .NewFormInput-Btn:hover{
        background: #FFC000;
    color: #FFFFFF;
    }

.MainSection .register {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #161e34;
}
.MainSection .register button {
  color: #4d1b7e;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
@media (max-width:600px){
  .loginContainer{
  padding:0;
}
}

@media (max-width: 560px) {
  .MainSection .title {
    font-weight: 500;
    font-size: 26px;
    line-height: 60px;
  }

  .MainSection .TitleDiv {
    padding: 28px;
  }
}
</style>
